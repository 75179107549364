import React from 'react';

const StandarProduct = (props) => {
  return (
    <div className='md:w-[28.75rem] space-y-2 font-[ubuntu] mt-20 font-bold shadow-lg starndarProducts'>
      <h1 className='text-4xl'>{props.nombre}</h1>
      {props.video ? (
        <video
          src={props.video}
          type='video/mp4'
          controls
          poster={props.img}
          className=''
        />
      ) : (
        <img src={props.img} alt='' className='' />
      )}
      <p>{props.descripcion}</p>
      <p>{props.descripcion2}</p>
      <p>{props.descripcion3}</p>
      <p>{props.descripcion4}</p>
      <p>{props.descripcion5}</p>
      <p>{props.descripcion6}</p>
    </div>
  );
};

export default StandarProduct;
