import React from 'react'
import Pico from '../Img/Pico.png'

const PreHero = () => {
  return (
    <div className='w-full'>
      <img src={Pico} alt="" className='object-contain w-full h-auto'/>
    </div>
  )
}

export default PreHero;