import React, { useState } from 'react';
import TPRLogo from '../../Img/TPRLogo.svg';
import CerealTools from '../../Img/CerealTools.png'
import Hormitec from '../../Img/Hormitec.png'


function AddClientsButton() {
  const [showComponent, setShowComponent] = useState(false);

  const handleClick = () => {
    setShowComponent(!showComponent);
  };

  const buttonText = showComponent ? "Mostrar Menos" : "Mostrar Más";

  return (
    <div>
       
      <button
        onClick={handleClick}
        className='bg-[#5DCC97] text-black py-2 px-4 rounded-3xl text-xl font-medium cursor-pointer'
      >
        {buttonText}
      </button>
      {showComponent && <RenderedComponent />}
    </div>
  );
}

function RenderedComponent() {
  return (
    <div>
      <div className='md:flex justify-evenly items-center block space-y-4 mt-3'>
        <img src={TPRLogo} alt='' data-aos="zoom-in-up" data-aos-delay="400"/>
        <img src={CerealTools} alt='' data-aos="zoom-in-up" data-aos-delay="500"/>
        <img src={Hormitec} alt='' data-aos="zoom-in-up" data-aos-delay="600" className=''/>
      </div>
    </div>
  );
}

export default AddClientsButton;
