import React, { useState } from 'react';
import EspecialProducts from '../SubComponents/SubComponent/SpecialProduct';
import ExampleImg from '../../Img/Ejemplo.png';
import DoyPack from '../../Img/Andrea_machine.jpeg';
import VideoPack from '../../Videos/DoyPack.mp4'
import Enfundadora from '../../Img/Enfundadora.png'
import VerbanoV from '../../Videos/Verbano.mp4'
import VerbanoI from '../../Img/VerbanoI.jfif'
import Tablero from '../../Img/Tablero.jpeg'
import PunzonadoraV from '../../Videos/Punzonadora.mp4'
import PunzonadoraI from '../../Img/Punzonadora.jpeg'
import EnsobradoraI from '../../Img/Ensobradora.jpeg'
import EnsobradoraV from '../../Videos/Ensobradora.mp4'

const ProductsListSpecial = () => {
  const [EspProducts, setEspProd] = useState([
    {
      nombre: 'Tableros Distribucion',
      img: Tablero,
      descripcion: '',
    },
    {
      nombre: 'Envasadora DoyPack',
      img: DoyPack,
      video: VideoPack,
      descripcion: '',
    },
    {
      nombre: 'Punzonadora',
      img: PunzonadoraI,
      video: PunzonadoraV,
      descripcion: '',
    },
    {
      nombre: 'Enfundadora',
      img: Enfundadora,
      descripcion: '',
    },
    {
      nombre: 'Espianadora',
      img: VerbanoI,
      video: VerbanoV,
      descripcion: '',
    },
    {
      nombre: 'Ensobradora Azucar',
      img: EnsobradoraI,
      video: EnsobradoraV,
      descripcion: '',
    },
    /*{
      nombre: 'xxx',
      img: ExampleImg,
      descripcion:''
    },*/
    /* {
      nombre: 'xxx',
      img: ExampleImg,
      descripcion:''
    },*/
  ]);
  return (
    <div
      className='lg:grid grid-cols-2 lg:mr-14 lg:space-x-40 justify-items-end md:mr-5'
      data-aos='fade-down-left'
      data-aos-delay='400'
    >
      {EspProducts.map((EspProd, index) => {
        return (
          <EspecialProducts
            key={index}
            nombre={EspProd.nombre}
            video={EspProd.video}
            img={EspProd.img}
            descripcion={EspProd.descripcion}
          />
        );
      })}
    </div>
  );
};

export default ProductsListSpecial;
